import { Paper, Box, Typography } from "@mui/material";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import randomKeyGenerator from "../../../utils/randomKeyGenerator";

const items = [
  {
    title: "Software Engineer",
    company: "Macro Solutions - FDIC",
    dates: "Jul 2023 - Present",
    duties: [
      "Worked on a team at the Federal Deposit Insurance Corporation (FDIC)",
      "Led the migration of FDIC’s API from cloud.gov to api.data.gov, overseeing the process from start to finish",
      "Utilized scrum processes to analyze and complete tasks on the project roadmap, creating additional tasks as needed to ensure seamless implementation",
      "Planned and updated code to facilitate the successful migration of the API",
      "Updated the entire frontend to utilize the new API route and ensured all tests reflected these changes",
      "Contributed to the development of the new BankFind Suite, a replacement for the FDIC’s old data visualization tools",
      "Assisted in building new features and fixing bugs for the BankFind Suite",
      "Developed code for both frontend and backend components",
      "Created web application features using JavaScript and React",
      "Built backend features using JavaScript, Node.js, and Elasticsearch",
      "Developed API endpoints to display critical data regarding data indexing processes",
      "Implemented logging for various data indexing operations",
      "Conducted code reviews and managed pull requests (PRs)"
    ]
  },
  {
    title: "Software Developer",
    company: "OtherWhy LLC",
    dates: "Mar 2022 - Jul 2023",
    duties: [
      "Built the scaffolding for a new web app using React, TypeScript, GraphQL Apollo, AWS Amplify, AWS Cognito, MUI, and Github",
      "Helped design and implement cloud infrastructure with Amazon services such as AWS Amplify and AWS Cognito",
      "Set up content management using a headless CMS with API calls",
      "Configured Google Cloud Platform to allow Cognito to authenticate users using Google Sign In",
      "Created login and registration pages using React, MUI, AWS Cognito, and GraphQL",
      "Deployed assets using a Content Delivery Network (CDN)",
      "Setup end to end testing as well as unit testing using different test frameworks",
      "Worked on an agile team using agile ceremonies such as standup, retro, grooming and commitment meetings",
      "Utilized Jira, Confluence, Slack, Figma, Miro and google workspace to collaborate cross-team with Technical Project Managers, Scrum Master, Architect, Product Managers, Designers and business stakeholders"
    ]
  },
  {
    title: "Technical Sales Development Representative",
    company: "Procore Technologies",
    dates: "Nov 2021 - Feb 2022",
    duties: [
      "Created new business opportunities to fuel Procore’s growth",
      "Engaged construction professionals through phone, email, and other channels to understand their challenges and identify opportunities to solve them",
      "Partnered with Account Executives to achieve mutually aligned sales quotas each month",
      "Autonomously operated beyond the role to help the team improve processes, training, use of technology, and any other aspects of business",
      "Worked cross-team with sales management to develop targeted lists, call strategies, and messaging to create opportunities for new business"
    ]
  },
  {
    title: "Sales Development Representative",
    company: "Advanced Systems Concepts, Inc.",
    dates: "Mar 2020 - May 2021",
    duties: [
      "Helped pioneer the Sales Development team for ASCI’s workload automation software, ActiveBatch",
      "Assessed, identified and implemented novel approaches and techniques to quickly identify high potential clients for ActiveBatch Workload Automation",
      "Engaged IT professionals through phone, email, and other channels to understand their challenges and identify opportunities to solve them",
      "Collaborated with Account Executives to organize discovery calls and product demos with a high rate of conversion and attendance",
      "Connected with current clients to discover new business opportunities",
      "Worked cross functionally to refine SDR procedures and maximize the effectiveness of the sales team"
    ]
  }
];

const ExpList = ({ width }) => {
  return items.map(({ title, company, dates, duties }) => {
    return (
      <Box key={randomKeyGenerator()}>
        <Box
          display={width < 600 ? null : "flex"}
          marginTop={4}
          marginBottom={1}
          textAlign={width < 600 ? "center" : null}
        >
          <Box flex={1}>
            <Typography variant="h5">{title}</Typography>
            <Typography variant="h6" gutterBottom fontStyle="italic">
              {company}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">{dates}</Typography>
            <hr />
          </Box>
        </Box>
        {duties.map((curr) => {
          if (curr.includes("LINK(")) {
            const linkRegex = /LINK\(([^)]+)\)/;
            const match = curr.match(linkRegex);
            const stringWithoutLink = curr.replace(linkRegex, "");

            const linkVariable = match ? match[1] : null;

            return (
              <Box
                display="flex"
                marginBottom={1}
                textAlign="justify"
                marginLeft={3}
                marginRight={3}
                key={randomKeyGenerator()}
              >
                {width <= 631 ? null : <SubdirectoryArrowRightIcon style={{ marginRight: 10 }} />}

                <Typography alignContent="center" gutterBottom>
                  {stringWithoutLink}{" "}
                  <a href={linkVariable} target="_blank">
                    (LINK)
                  </a>
                </Typography>
              </Box>
            );
          } else {
            return (
              <Box
                display="flex"
                marginBottom={1}
                textAlign="justify"
                marginLeft={3}
                marginRight={3}
                key={randomKeyGenerator()}
              >
                {width <= 631 ? null : <SubdirectoryArrowRightIcon style={{ marginRight: 10 }} />}

                <Typography alignContent="center" gutterBottom>
                  {curr}
                </Typography>
              </Box>
            );
          }
        })}
      </Box>
    );
  });
};

const Experience = ({ width }) => {
  return (
    <Box marginBottom={4} marginTop={4}>
      <Paper elevation={4}>
        <Box padding={2.5}>
          <Typography variant="h4" align="center">
            Experience
          </Typography>
          <hr />
          <ExpList width={width} />
        </Box>
      </Paper>
    </Box>
  );
};

export default Experience;
